import * as io from 'socket.io-client';
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const link = document.createElement('a');
link.href = SOCKET_URL;


const socketManagers = {
  'default': new io.Manager(link.href, {
    autoConnect: true,
    reconnection: true,
    transports: ['websocket']
  })
};

const socketPools = {};

export const getSocket = (namespace, serverId = "default") => {
  const socketId = `${serverId}.${namespace}`;
  if (socketPools[socketId]) {
    return socketPools[socketId];
  }
  const socketManager = socketManagers[serverId] ?? new io.Manager(link.href, {
    autoConnect: true,
    reconnection: true,
    transports: ['websocket'],
    query: `server_id=${serverId}`
  });

  let sock = socketManager.socket(namespace);
  socketPools[socketId] = sock;
  sock.on('disconnect', (e) => {
    console.log('client disconnected', e);
    socketPools[socketId] = undefined;
  });
  return sock;
};

const socket = getSocket('/');
const checkAuthInfo = () => {
  socket.emit('authInfo', {}, (res) => {
    if (res?.result) {
      localStorage.setItem('user', JSON.stringify(res?.result));
    } else {
      localStorage.removeItem('user');
    }
  });
};
checkAuthInfo();
setInterval(checkAuthInfo, 10000);

export default socket;
