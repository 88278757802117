import React from 'react';
import { useState, useEffect } from 'react';
import '../Css/Game.css';
import '../Css/GameMain.css';
import '../Css/GameMobile.css';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import useSound from 'use-sound';
import chipsMove from '../Audio/call.wav';
import collect from '../Audio/collect.wav';
import foldCard from '../Audio/fold.mp3';
import cardDealt from '../Audio/deal_cards.wav';
import checkNew from '../Audio/checkTurn.mp3';
import myTurn from '../Audio/my_turn.wav';
import winner from '../Audio/win_sound.mp3';
import card_shuffle from '../Audio/card_shuffle.wav';
import TableSetting from '../Components/TableSetting';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { setDefaultLanguage } from 'react-multi-lang';
import { CompLoading } from '../Components/CompLoading';
import { CompGameButton } from '../Components/CompGameButton';
import { CompGameIconButton } from '../Components/CompGameIconButton';
import { toast, ToastContainer } from 'react-toastify';
import { t } from 'react-multi-lang';

import IconQuestion from '../Images/GameScreen/question.png';
import IconNext from '../Images/GameScreen/next.png';
import IconMessage from '../Images/GameScreen/message.png';

import { ComppViewport } from '../Components/CompViewport';
import { CompLeaveMenu } from '../Components/CompLeaveMenu';
import { CompBuyInModal } from '../Components/CompBuyInModal';
import {
  CompPlayerInfoPopupContainer,
  showPlayerInfoPopup,
} from '../Components/CompPlayerInfoPopup';

import chipBW from '../Images/chipBW.png';
import { CompSettingMenu } from '../Components/CompSettingMenu';
import { CompCardInfoModal } from '../Components/CompCardInfoModal';
import { CompRaiseSlider } from '../Components/CompRaiseSlider';
import { CompTournamentWinnerModal } from '../Components/CompTournamentWinnerModal';
import { CompOpenBuyInModal } from '../Components/CompOpenBuyInModal';
import socket, { getSocket } from '../socket';
import { CompPlayerSeat } from '../Components/CompPlayerSeat';
import { CompGamePotButton } from '../Components/CompGamePotButton';
import { CompSvgText } from '../Components/CompSvgText';
import { useAuth } from '../Hooks/useAuth';

import cardBack from '../Images/Deck/Back/default.png';
import { cardFronts, tableLogo, clownImg, tableBg, chipsPrimary, chipsSecondary, dealerIcon } from '../images';
import { CompChatbox } from '../Components/CompChatbox';
import { CompPlayerEmojiBar } from '../Components/CompPlayerEmojiBar';


const BackendURL = process.env.REACT_APP_SOCKET_URL;

const GameScreen = () => {
  const handle = useFullScreenHandle();
  const [voice, setVoice] = useState(true);
  const [chipsVoice] = useSound(chipsMove);
  const [foldCardVoice] = useSound(foldCard);
  const [cardDealtVoice] = useSound(cardDealt);
  const [checkActionVoice] = useSound(checkNew);
  const [myTurnVoice] = useSound(myTurn);
  const [winnerVoice] = useSound(winner);
  const [collectVoice] = useSound(collect);
  const [cardShuffleVoice] = useSound(card_shuffle);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [gameChatMessages, setGameChatMessages] = useState([]);

  const [socketIo, setSocketIo] = useState();
  const [tableSeats, setTableSeats] = useState([0, 1, 2, 3, 4]);
  const [emptyTable, setEmptyTable] = useState(true);
  const [playerInfo, setPlayerInfo] = useState([]);
  const [gameStart, setGameStart] = useState(false);
  const [tableData, setTableData] = useState();
  const [minBuyIn, setMinBuyIn] = useState(0);
  const [maxBuyIn, setMaxBuyIn] = useState(100);
  const [start, setStart] = useState(0);
  const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
  const [seatPosition, setSeatPosition] = useState();
  const [buyIn, setBuyIn] = useState(false);
  const navigate = useNavigate();
  const [roomData, setRoomData] = useState({});
  const [tablePot, setTablePot] = useState(0);
  const [playerCards, setPlayerCards] = useState({});
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isRaiseAmount, setIsRaiseAmount] = useState(false);
  const [isOnOpenBuyInPanel, setIsOnOpenBuyInPanel] = useState(false);
  const [playerActionType, setPlayerActionType] = useState('');
  const [playerActionData, setPlayerActionData] = useState(0);
  const [raiseAmount, setRaiseAmount] = useState('');
  const [gameFinishedPlayersCards, setGameFinishedPlayersCards] = useState([]);
  const [winners, setWinners] = useState([]);
  const [winnerSeatIndex, setWinnerSeatIndex] = useState(-1);
  const [winningAmount, setWinningAmount] = useState(-1);
  const [bestCard, setBestCard] = useState([]);
  const [idealPlayer, setIdealPlayer] = useState({});
  const [gamePlayData, setGamePlayData] = useState({});
  const [playerSidePot, setPlayerSidePot] = useState(0);
  const [hiddenCards, sethiddenCards] = useState([]);
  const [smallBlindChips, setSmallBlindChips] = useState(0);
  const [bigBlindChips, setBigBlindChips] = useState(0);
  const [playerGiftData, setPlayerGiftData] = useState({});
  const [dealerPlayerId, setDealerPlayerId] = useState('');
  const [dealerSeatIndex, SetDealerSeatIndex] = useState(-1);
  const [isThreeDotShow, setThreeDotShow] = useState(false);
  const [kickoutTimeout, setKickoutTimeout] = useState(120);
  const [isShowChatbox, setIsShowChatbox] = useState(false);
  const [numOfPlayers, setNumOfPlayers] = useState(0);
  const [reactEmojis, setReactEmojis] = useState([]);

  const [mobileView, setMobileView] = useState({
    width: '100%',
    height: '100%',
    fontSize: '0',
  });
  const [tableImage, setTableImage] = useState({
    width: `${1176}px`,
    height: `${698}px`,
    fontSize: `${18.5}px`,
  });

  const [isMobile, setIsMobile] = useState(true);
  const [roomType, setRoomType] = useState('');
  const [gameType, setGametype] = useState('');
  const [isLoader, setIsLoader] = useState(true);
  const { tableId } = useParams();
  const [isTournamentTable, setIsTournamentTable] = useState(false);
  const [isTournamentWinnerDisplay, setIsTournamentWinnerDisplay] =
    useState(false);
  const [tournamentWinner, setTournamentWinner] = useState({});


  const [isShowLeaveMenu, setShowLeaveMenu] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenCardInfo, setOpenCardInfo] = useState(false);
  const [tournamentType, setTournamentType] = useState('');
  const [gameRoomData, setGameRoomData] = useState(null);

  const [parentRoomId, setParentRoomId] = useState('');

  const auth = useAuth({ redirect: `/game/${tableId}` });
  const playerData = auth.user;

  useEffect(() => {
    if (playerInfo && dealerPlayerId) {
      const dealer = playerInfo.find(p => p.id === dealerPlayerId);
      if (dealer) {
        SetDealerSeatIndex(dealer.seatIndex);
      }
    }
  }, [playerInfo, dealerPlayerId]);


  useEffect(() => {
    if (reactEmojis.length === 0 && (winnerSeatIndex >= 0 || winners.length > 0)) {
      const curPlayer = playerInfo.find(p => p.id === playerData?.playerId);
      const isWinner = curPlayer?.seatIndex === winnerSeatIndex || winners.find(w => w.playerId === playerData?.playerId);
      if (isWinner) {
        setReactEmojis(['1f600', '1f970', '1f973']);
      } else if (curPlayer?.status !== 'Left' && curPlayer?.status !== 'Ideal') {
        setReactEmojis(['1f624', '1f622', '1f92c']);
      }
      return;
    }
    if (reactEmojis.length !== 0 && (winnerSeatIndex < 0 && winners.length === 0)) {
      setReactEmojis([]);
    }
  }, [winnerSeatIndex, winners, playerData, playerInfo, reactEmojis]);

  useEffect(() => {
    const pid = setInterval(() => {
      if (gameStart && playerInfo.length > 1) {
        setKickoutTimeout(120);
      } else {
        setKickoutTimeout(kickoutTimeout - 1);
      }
      if (kickoutTimeout <= 0) {
        clearInterval(pid);
        leaveGame();
      }
    }, 1000);
    return () => {
      clearInterval(pid);
    }
  }, [gameStart, kickoutTimeout, playerInfo]);

  useEffect(() => {
    setNumOfPlayers(playerInfo.length);
  }, [playerInfo]);

  useEffect(() => {
    const mergeRoom = (data) => {
      if (
        isRoomModalOpen ||
        data.roomId !== tableId ||
        !data.parentRoomId ||
        !data.players.find(p => p.playerId === playerData?.playerId)
      ) {
        return;
      }
      const currentPlayer = data.players.find(p => p.playerId === playerData?.playerId);
      socket.emit('gameMergeRoomData', {
        parentRoomId: data.parentRoomId
      }, (res) => {
        if (res.result) {
          console.log('OnMergeRoom', res.result);
          const { roomId, serverId, namespaceString } = res.result;
          if (roomId !== tableId) {
            socketIo.emit('LeaveRoom', {
              playerId: playerData?.playerId,
              roomId: tableId,
              mergeRoom: true,
            }, () => {
              const newSocket = getSocket(`/${namespaceString}`, serverId);
              setSocketIo(newSocket);
              newSocket.emit('JoinRoom', {
                playerId: playerData?.playerId,
                roomId,
                chips: currentPlayer.chips,
                seatIndex: -1,
              }, (resJoinRoom) => {
                if (resJoinRoom.result) {
                  swicthRoom(roomId);
                } else {
                  navigate(`/lobby`);
                }
              });
            });
          }
        }
      });
    };
    if (socketIo) {
      socketIo.on('OnMergeRoom', mergeRoom);
      return () => {
        socketIo.off('OnMergeRoom', mergeRoom);
      }
    }
  }, [socketIo, playerData, tableId, isRoomModalOpen]);


  useEffect(() => {
    setIsLoader(false);
  });

  const getGameRoomData = () => {
    getSocket('/', auth?.user?.serverId).emit('gameRoomData', {
      id: tableId,
      playerId: playerData?.playerId
    }, (res) => {
      if (res.result) {
        const result = res.result;
        const room = res.result;
        if (room.roomId !== tableId) {
          swicthRoom(room.roomId);
          return;
        }
        setParentRoomId(room.parentRoomId ?? '');
        setGameRoomData(room);
        setRoomType(result.namespaceString);
        setGametype(room.tableType);
        setIsTournamentTable(room.isTournamentTable);
        setTournamentType(room.tournamentType);
        if (
          room.tournamentType === 'sng' ||
          room.tournamentType === 'regular'
        ) {
          setEmptyTable(false);
        }

        let tableRoomType = result.namespaceString;
        const socket = getSocket(`/${tableRoomType}`, result.serverId);
        setSocketIo(socket);
        sendSubscribeRoom(socket);
      } else {
        navigate(`/lobby`);
      }
    });
  }

  useEffect(() => {
    if (playerData?.playerId && tableId) {
      getGameRoomData();
    }
  }, [tableId, playerData?.playerId, auth?.user?.serverId]);

  useEffect(() => {
    const curPlayer = playerInfo.find(p => p.id === playerData?.playerId && p.status !== 'Left');
    if (emptyTable && playerInfo && roomData && playerInfo.length < roomData.maxPlayers && !curPlayer) {
      selectBuyIn(-1);
    } else {
      setIsRoomModalOpen(false);
    }
  }, [emptyTable, playerInfo, roomData]);


  const swicthRoom = (roomId) => {
    setBestCard([]);
    setGameStart(false);
    setPlayerActionType('');
    setGamePlayData({});
    setIsPanelOpen(false);
    setRaiseAmount(0);
    setTablePot(0);
    setPlayerSidePot(0);
    setWinners([]);
    setGameFinishedPlayersCards({});
    sethiddenCards([]);
    setPlayerInfo([]);
    navigate(`/game/${roomId}`);
  }

  useEffect(() => {
    if (
      gameRoomData &&
      gameRoomData.isCashGame &&
      gameRoomData.parentRoomId &&
      playerData &&
      playerInfo.length >= roomData.maxPlayers &&
      !playerInfo.find(p => p.id === playerData.playerId)
    ) {
      swicthRoom(gameRoomData.parentRoomId);
    }
  }, [playerInfo]);


  useEffect(() => {
    if (socketIo) {
      const onDisconnect = () => {
        setTimeout(() => {
          sendSubscribeRoom(socketIo);
        }, 500);
      }
      socketIo.on('disconnect', onDisconnect);
      return () => {
        socketIo.off('disconnect', onDisconnect);
      }
    }
  }, [socketIo, hiddenCards, tableId, playerData?.playerId]);


  useEffect(() => {
    const id = setInterval(() => {
      socket.emit('gamePlayedLastTime', {
        playerId: playerData?.playerId,
        productName: 'BytePoker',
      }, () => {
        // console.log('gamePlayedLastTime', res);
      });
    }, 10000);
    return () => clearInterval(id);
  }, [tableId]);

  const sendSubscribeRoom = (sock) => {
    const data = {
      playerId: playerData?.playerId,
      roomId: tableId,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    sock.emit('SubscribeRoom', data, (res) => {
      if (res.result) {
        setRoomData(res.result);
        if (res.result?.gameHistory?.cards.length !== hiddenCards.length) {
          sethiddenCards(res.result.gameHistory.cards);
        }
        setSmallBlindChips(res.result.smallBlindChips);
        setBigBlindChips(res.result.bigBlindChips);
        setDealerPlayerId(res.result.dealerPlayerId);
      }
    });
  };

  const onChatMsg = (msg) => {
    setGameChatMessages([...gameChatMessages, msg].filter(m => m.expiredAt > Date.now()));
  };

  const onGameFinished = (data) => {
    if (!data || data.roomId !== tableId) {
      return;
    }
    setPlayerSidePot({});
    setIsPanelOpen(false);
    setGamePlayData({});
    setWinners([...data?.winners ?? []]);
    if (data.winners.length > 0) {
      setWinnerSeatIndex(data.winners[0].winnerSeatIndex);
      setWinningAmount(data.winners[0].amount);
      setBestCard(data.winners[0].bestCards);
      setTimeout(() => {
        setWinners([]);
        setWinnerSeatIndex(-1);
        setWinningAmount(-1);
        setBestCard([]);
        setGameStart(false);
        sethiddenCards([]);
        onSetPlayerCards({});
      }, 4000);
    }
  };

  const onSetPlayerCards = (data) => {
    setPlayerCards(data);
  }

  useEffect(() => {
    if (socketIo && tableId) {
      // socketIo.on('PlayerGift', setPlayerGiftData);
      // socketIo.on('OnReservedSeatList', console.log);
      sendSubscribeRoom(socketIo);
      socketIo.on('Chat', onChatMsg);
      socketIo.on('OnSubscribeRoom', OnSubscribeRoomReceived);
      socketIo.on('RegularTournamentFinished', OnTournamentFinished);
      socketIo.on('SngTournamentFinished', OnTournamentFinished);
      socketIo.on('playerInfoList', PlayerInfoListReceived);
      // socketIo.on('OnGameStartWait', console.log);
      // socketIo.on('superPlayerData', console.log);
      // socketIo.on('OnReservedSeatList', console.log);
      // socketIo.on('RevertPoint', console.log);
      // socketIo.on('OnWaitingJoinRoom', console.log);

      // socketIo.emit('OnPlayersCardsDistribution', { tableId });
      // socketIo.on('OnPlayersCardsDistribution', console.log);

      socketIo.on('GameFinishedPlayersCards', setGameFinishedPlayersCards);
      socketIo.on('GameFinished', onGameFinished);

      return () => {
        socketIo.off('Chat', onChatMsg);
        socketIo.off('OnSubscribeRoom', OnSubscribeRoomReceived);
        socketIo.off('RegularTournamentFinished', OnTournamentFinished);
        socketIo.off('SngTournamentFinished', OnTournamentFinished);
        socketIo.off('playerInfoList', PlayerInfoListReceived);
        socketIo.off('GameFinishedPlayersCards', setGameFinishedPlayersCards);
        socketIo.off('GameFinished', onGameFinished);
      };
    }
  }, [socketIo, tableId, numOfPlayers]);

  useEffect(() => {
    const handleResize = () => {
      const mobileFontSize = (1080 * 62.68) / 450;
      setMobileView({
        width: `${1080}px`,
        height: `${1920}px`,
        fontSize: `${mobileFontSize}%`,
      });
      const baseFontSize = 9;
      const baseWidth = 360;
      const baseHeight = 522;
      const windowWidth = 1080; // replace with actual window width
      const windowHeight = 1920 * 1.3; // replace with actual window height
      const widthScale = windowWidth / baseWidth;
      const heightScale = windowHeight / baseHeight;
      const scale = Math.min(widthScale, heightScale);
      const scaledFontSize = baseFontSize * scale;
      setTableImage({
        width: `${1080}px`,
        height: `${windowHeight}px`,
        fontSize: `${scaledFontSize}px`,
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);



  const onRevertPointFolded = (data) => {
    if (!data || data.roomId !== tableId) {
      return;
    }
    let gamePlayer = playerInfo;
    if (gamePlayer.length > 0) {
      let playerInfoData = gamePlayer.find((playerEle) => {
        if (playerEle.id === data.playerId) {
          return playerEle;
        }
      });

      var index = gamePlayer.indexOf(playerInfoData);
      if (gamePlayer[index]) {
        gamePlayer[index].BuyInAmount = data.chips;
      }

      if (gamePlayer.length > 0) {
        handlePlayerInfo(gamePlayer);
      }
    }
    setDealerPlayerId('');
    setWinnerSeatIndex(data.winnerSeatIndex);
    setWinningAmount(data.amount);
    setBestCard([]);
    onSetPlayerCards({});
    setTimeout(() => {
      setWinnerSeatIndex(-1);
      setWinningAmount(-1);
      if (playerInfo.length < 2) {
        sethiddenCards([]);
      }
    }, 4000);
  };

  const onPlayerLeft = (data) => {
    if (data.roomId !== tableId || data.mergeRoom) {
      return;
    }

    if (data.playerId === playerData?.playerId) {
      navigate("/lobby");
    } else {
      let gamePlayer = playerInfo;
      if (gamePlayer.length > 0) {
        let playerInfoData = gamePlayer.find((playerEle) => {
          if (playerEle.id === data.playerId) {
            return playerEle;
          }
        });

        var index = gamePlayer.indexOf(playerInfoData);
        if (index !== -1) {
          gamePlayer.splice(index, 1);
          handlePlayerInfo([...gamePlayer]);
        }
        if (idealPlayer.playerId == data.playerId) {
          setIdealPlayer({});
        }
      }
    }
  };

  const onSwitchRoom = (data) => {
    if (data && data.roomId == tableId) {
      navigate(`/game/${data.newRoomId}`);
    }
  };

  useEffect(() => {
    if (socketIo) {
      socketIo.on('OnSwitchRoom', onSwitchRoom);
      socketIo.on('PlayerLeft', onPlayerLeft);
      socketIo.on('RevertPointFolded', onRevertPointFolded);
      return () => {
        socketIo.off('OnSwitchRoom', onSwitchRoom);
        socketIo.off('PlayerLeft', onPlayerLeft);
        socketIo.off('RevertPointFolded', onRevertPointFolded);
      };
    }
  }, [socketIo, playerInfo, tableId, gameStart]);

  useEffect(() => {
    if (winnerSeatIndex >= 0) {
      playVoice(6);
    }
  }, [winnerSeatIndex]);

  useEffect(() => {
    if (gameStart == true) {
      playVoice(8);
    }
  }, [gameStart]);

  useEffect(() => {
    if (playerActionType != '') {
      if (playerActionType == 6) {
        playVoice(2);
      } else {
        if (playerActionType == 2) {
          playVoice(4);
        } else {
          playVoice(1);
        }
      }
    }
  }, [playerActionType]);

  const playVoice = (type) => {
    if (voice) {
      if (type == 1) {
        chipsVoice();
      }
      if (type == 2) {
        foldCardVoice();
      }
      if (type == 3) {
        cardDealtVoice();
      }
      if (type == 4) {
        checkActionVoice();
      }
      if (type == 5) {
        myTurnVoice();
      }
      if (type == 6) {
        winnerVoice();
      }
      if (type == 7) {
        collectVoice();
      }
      if (type == 8) {
        cardShuffleVoice();
      }
    }
  };

  const handlePlayerInfo = (playerInfoArr) => {
    if (playerInfoArr.length > 0) {
      let playerDataInfo = playerInfoArr.find((playerEle) => {
        return playerEle.id === playerData?.playerId;
      });
      if (playerDataInfo) {
        setSeatPositionCss(playerDataInfo.seatIndex);
      }
      playerInfoArr.map((player, index) => {
        if (playerInfoArr[index].BuyInAmount) {
          playerInfoArr[index].BuyInAmount = playerInfoArr[index].BuyInAmount;
        } else {
          if (player.waitingGameChips > 0) {
            playerInfoArr[index].BuyInAmount = player.waitingGameChips;
          } else {
            playerInfoArr[index].BuyInAmount = player.chips;
          }
          if (player.id == playerData?.playerId) {
            setEmptyTable(false);
          }
        }
        if (playerInfoArr[index].BetAmount) {
          playerInfoArr[index].BetAmount = playerInfoArr[index].BetAmount;
        } else {
          playerInfoArr[index].BetAmount = player.betAmount;
        }
      });
      setPlayerInfo(playerInfoArr);
    }
  };
  const OnTournamentFinished = (data) => {
    if (data && data.roomId === tableId) {
      setIsTournamentWinnerDisplay(true);
      setTournamentWinner(data);
    }
  };

  const OnSubscribeRoomReceived = (data) => {
    if (data && data.roomId === tableId) {
      setRoomData(data);
      if (data.gameHistory?.cards) {
        sethiddenCards(data.gameHistory.cards);
      }
      setSmallBlindChips(data.smallBlindChips);
      setBigBlindChips(data.bigBlindChips);
      setDealerPlayerId(data.dealerPlayerId);
    }
  };

  useEffect(() => {
    if (socketIo && tableId) {
      socketIo.emit('OnGameBoot', { tableId });
      socketIo.emit('RoundComplete', { tableId });
      socketIo.emit('OnTurnTimer', { tableId });
      socketIo.emit('GameStarted', { tableId });
      socketIo.emit('OnPlayerCards', { tableId });
      socketIo.emit('onIdealPlayer', { tableId });
      socketIo.emit('OnOpenBuyInPanel', { tableId });

      socketIo.on('OnGameBoot', OnGameBootReceived);
      socketIo.on('PlayerAction', OnPlayerActionReceived);
      socketIo.on('RoundComplete', RoundCompleteReceived);
      socketIo.on('GameStarted', OnGameStartReceived);
      socketIo.on('OnPlayerCards', OnPlayerCardsReceived);
      socketIo.on('onIdealPlayer', OnIdealPlayerReceived);
      socketIo.on('OnOpenBuyInPanel', OnOpenBuyInPanelReceived);
      socketIo.on('OnTurnTimer', OnTurnTimerReceived);
      return () => {
        socketIo.off('OnGameBoot', OnGameBootReceived);
        socketIo.off('PlayerAction', OnPlayerActionReceived);
        socketIo.off('RoundComplete', RoundCompleteReceived);
        socketIo.off('GameStarted', OnGameStartReceived);
        socketIo.off('OnPlayerCards', OnPlayerCardsReceived);
        socketIo.off('onIdealPlayer', OnIdealPlayerReceived);
        socketIo.off('OnOpenBuyInPanel', OnOpenBuyInPanelReceived);
        socketIo.off('OnTurnTimer', OnTurnTimerReceived);
      };
    }
  }, [socketIo, tableId, playerInfo]);

  const PlayerInfoListReceived = (data) => {
    if (data && data.roomId === tableId) {
      handlePlayerInfo(data.playerInfo);
    }
  };

  const OnOpenBuyInPanelReceived = (data) => {
    if (data && data.roomId === tableId) {
      setIsOnOpenBuyInPanel(true);
    }
  };

  const OnGameBootReceived = (data) => {
    if (data && data.roomId === tableId) {
      setDealerPlayerId(data.dealerPlayerId);
      setWinnerSeatIndex(-1);
      setWinningAmount(-1);
      let gamePlayer = playerInfo;
      if (gamePlayer.length > 0) {
        gamePlayer?.map((plr, i) => {
          if (plr.id != null) {
            gamePlayer[i].status = 'Playing';
            if (plr.id == data.dealerPlayerId) {
              gamePlayer[i].isDealer = true;
            } else {
              gamePlayer[i].isDealer = false;
            }
            if (plr.id == data.smallBlindPlayerId) {
              gamePlayer[i].BetAmount = data.smallBlindChips;
              gamePlayer[i].BuyInAmount = data.smallBlindPlayerChips;
            }
            if (plr.id == data.bigBlindPlayerId) {
              gamePlayer[i].BetAmount = data.bigBlindChips;
              gamePlayer[i].BuyInAmount = data.bigBlindPlayerChips;
            }
            if (
              data.straddlePlayerId != null &&
              plr.id == data.straddlePlayerId
            ) {
              gamePlayer[i].BetAmount = data.straddleChips;
              gamePlayer[i].BuyInAmount = data.straddlePlayerChips;
            }

            data?.bigBlindPlayerList?.map((bigBlindPlr, k) => {
              if (plr.id == bigBlindPlr.playerId) {
                gamePlayer[k].BetAmount = bigBlindPlr.chips;
                gamePlayer[k].BuyInAmount = bigBlindPlr.playerChips;
              }
            });
          }
        });
        handlePlayerInfo(gamePlayer);
      }
      sethiddenCards([]);
      setTablePot(data.totalTablePotAmount);
      setSmallBlindChips(data.smallBlindChips);
      setBigBlindChips(data.bigBlindChips);
      setPlayerCards({});
    }
  };

  const OnIdealPlayerReceived = (data) => {
    if (data && data.roomId === tableId) {
      if (data.status == true) {
        setIdealPlayer(data);
        setPlayerInfo(playerInfo.map(player => {
          if (player.id === data.playerId) {
            player.status = 'Ideal';
          }
          return player;
        }));
      } else {
        setIdealPlayer({});
        setPlayerInfo(playerInfo.map(player => {
          if (player.id === data.playerId) {
            player.status = 'Playing';
          }
          return player;
        }));
      }
    }
  };

  const RoundCompleteReceived = (data) => {
    if (data && data.roomId === tableId) {
      const sidePot = data.PlayerSidePot;
      setPlayerSidePot(sidePot.mainPot);
      if (sidePot.mainPot > 0) {
        let gamePlayer = playerInfo;
        if (gamePlayer.length > 0) {
          gamePlayer?.map((pyr, index) => {
            if (gamePlayer[index].BetAmount > 0) {
              // setTimeout(() => {
              //     playVoice(7)
              // }, 300)
            }
            gamePlayer[index].BetAmount = 0;
          });
          handlePlayerInfo(gamePlayer);
        }
      }
      if (playerInfo.length > 1) {
        sethiddenCards(data.cards);
      }
    }
  };

  const OnPlayerActionReceived = (data) => {
    if (data.roomId === tableId) {
      setIsPanelOpen(false);
      if (data.action.action == 6) {
        handlePlayerInfo(playerInfo.map(player => {
          if (player.id === data.action.playerId) {
            player.status = 'Fold';
          }
          return player;
        }));
      }
      setPlayerActionType(data.action.action);
      setPlayerActionData(data.action);
      setTimeout(() => {
        setPlayerActionData({});
      }, 2000);
      let gamePlayer = [...playerInfo];
      if (gamePlayer.length > 0) {
        let playerInfoData = gamePlayer.find((playerEle) => {
          if (playerEle.id === data.action.playerId) {
            return playerEle;
          }
        });

        var index = gamePlayer.indexOf(playerInfoData);
        if (gamePlayer[index]) {
          gamePlayer[index].BuyInAmount = data.playerBuyIn;
          gamePlayer[index].BetAmount = data.action.totalBetAmount;
        }
      }
      setTablePot(data.totalTablePotAmount);
    }
  };
  const OnTurnTimerReceived = (data) => {
    if (data.roomId === tableId) {
      setGameStart(true);
      setPlayerActionType('');
      setGamePlayData(data);
      setIsPanelOpen(true);

      if (isRaiseAmount) {
        if (data.playerId !== playerData?.playerId || data.timer === 0 || data.timer === data.maxTimer) {
          setIsRaiseAmount(false);
        }
      }

      if (
        data.playerId == playerData?.playerId &&
        data.timer == data.maxTimer - 1
      ) {
        playVoice(5);
      }
    }
  };

  const OnGameStartReceived = (data) => {
    if (data && data.roomId === tableId) {
      setWinners([]);
      setGameFinishedPlayersCards({});
      setWinnerSeatIndex(-1);
      setWinningAmount(-1);
      sethiddenCards([]);
      setGameStart(true);
    }
  };

  const OnPlayerCardsReceived = (data) => {
    if (
      data &&
      data.roomId === tableId &&
      data.playerId === playerData?.playerId
    ) {
      onSetPlayerCards(data);
    } else {
      console.log('Wrong Player Cards Received');
    }
  };

  const setSeatPositionCss = (seat) => {
    if (seat == 0) {
      setTableSeats([3, 4, 0, 1, 2]);
    }
    if (seat == 1) {
      setTableSeats([4, 0, 1, 2, 3]);
    }
    if (seat == 2) {
      setTableSeats([0, 1, 2, 3, 4]);
    }
    if (seat == 3) {
      setTableSeats([1, 2, 3, 4, 0]);
    }
    if (seat == 4) {
      setTableSeats([2, 3, 4, 0, 1]);
    }
  };

  const getFillCss = (seat) => {
    return `${tableSeats.indexOf(seat) + 1}-m5`;
  };
  const checkPlayerSeat = (player, seat) => {
    let seatIndex = playerInfo.find((playerEle) => {
      return playerEle.seatIndex === seat;
    });

    if (seatIndex != undefined) {
      if (seatIndex.id == player.id) {
        return true;
      }
    }
    return false;
  };


  const selectBuyIn = (seat = -1) => {
    const mainSocket = socket; // io(URL);
    const data = {
      playerId: playerData?.playerId,
      roomId: tableId,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    mainSocket.emit('GetBuyinsAndPlayerchips', data, (res) => {
      if (res.status == 'success') {
        setTableData(res.result);
        setMinBuyIn(res.result.minBuyIn);
        console.log(res.result);
        if (res.result.maxBuyIn > 0) {
          setMaxBuyIn(res.result.maxBuyIn);
        } else {
          setMaxBuyIn(playerData?.chips);
        }
        setStart(res.result.minBuyIn);
        setIsRoomModalOpen(true);
        setSeatPosition(seat);
      } else {
        toast.error(t(res.message), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  };

  const sitOnTable = (type) => {
    const data = {
      playerId: playerData?.playerId,
      roomId: tableId,
      chips: start,
      seatIndex: seatPosition,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    setIsRoomModalOpen(false);
    if (type == 0) {
      socketIo.emit('JoinRoom', data, (res) => {
        if (res.result) {
          setEmptyTable(false);
        } else {
          swicthRoom(parentRoomId);
        }
      });
    }
    if (type == 1) {
      socketIo.emit('PlayerAddChips', data, (res) => {
        if (res.result) {
          setBuyIn(false);
          if (res.message) {
            toast.success(t(res.message), {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
          }
        }
        if (!res?.result && res.message) {
          toast.error(t(res.message), {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      });
    }
  };



  const leaveGame = () => {
    const data = {
      playerId: playerData?.playerId,
      roomId: tableId,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    socketIo.emit('LeaveRoom', data, () => {
      setSocketIo('');
      navigate('/lobby');
    });
  };

  const standUp = () => {
    const data = {
      playerId: playerData?.playerId,
      roomId: tableId,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    socketIo.emit('LeaveRoom', data, () => {
      setEmptyTable(true);
      setGameStart(false);
      getGameRoomData();
    });
  };

  const sitOutCheckbox = (e, type) => {
    let data = {
      nextHand: e.target.checked,
      bigBlind: false,
    };
    if (type == 1) {
      data = {
        nextHand: false,
        bigBlind: e.target.checked,
      };
    }
    const SitOutNextHandData = {
      playerId: playerData?.playerId,
      roomId: tableId,
      actionValue: data.nextHand,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    const SitOutNextBigBlindData = {
      playerId: playerData?.playerId,
      roomId: tableId,
      actionValue: data.bigBlind,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    socketIo.emit('SitOutNextBigBlind', SitOutNextBigBlindData, () => { });
    socketIo.emit('SitOutNextHand', SitOutNextHandData, () => { });
  };

  const PlayerAction = (action, hasRaised, amount) => {
    if (action == 6) {
      // let playerCardsOld = playerCards;
      // playerCardsOld.flipAnimation = false
      // setPlayerCards(playerCardsOld)
      // playVoice(2)
    } else {
      // if (action == 2) {
      //     playVoice(4)
      // } else {
      //     playVoice(1);
      // }
    }

    const data = {
      playerId: playerData?.playerId,
      betAmount: amount,
      action: action,
      roomId: tableId,
      hasRaised: hasRaised,
      productName: 'BytePoker',
    };


    socketIo.emit('PlayerAction', data, () => {
      setIsPanelOpen(false);
    });
  };

  const getActionName = (actionData) => {
    const action = actionData.action;
    const hasRaised = actionData.hasRaised;
    if (action == 0) {
      return 'SmallBlind';
    } else if (action == 1) {
      return 'BigBlind';
    } else if (action == 2) {
      return 'Check';
    } else if (action == 3) {
      if (hasRaised == true) {
        return 'Raise';
      } else {
        return 'Bet';
      }
    } else if (action == 4) {
      return 'Call';
    } else if (action == 6) {
      return 'Fold';
    } else if (action == 7) {
      return 'Timeout';
    } else if (action == 8) {
      return 'Allin';
    } else {
      return '';
    }
  };

  const raiseAmountChange = (value) => {
    console.log('raiseAmountChange', value);
    setRaiseAmount(value);
  };


  const sendPlayerOnline = () => {
    const data = {
      playerId: playerData?.playerId,
      roomId: tableId,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    socketIo.emit('PlayerOnline', data, (res) => {
      console.log('PlayerOnline', res);
      sendSubscribeRoom(socketIo);
    });
  };


  const cardFlipice = () => {
    // if (time == 0) {
    //   setTimeout(() => {
    //     playVoice(3);
    //   }, 100);
    // }
    // if (time == 1) {
    //   setTimeout(() => {
    //     playVoice(3);
    //   }, 200);
    // }
    // if (time == 2) {
    //   setTimeout(() => {
    //     playVoice(3);
    //   }, 300);
    // }
    // if (time == 3) {
    //   setTimeout(() => {
    //     playVoice(3);
    //   }, 100);
    // }
    // if (time == 4) {
    //   setTimeout(() => {
    //     playVoice(3);
    //   }, 100);
    // }
  };

  const hidddenCardClassName = (className) => {
    if (className == 'hiddenCard1') {
      setTimeout(() => {
        const hiddenCard1 = document.querySelector('.' + className);
        if (hiddenCard1) {
          hiddenCard1.classList.add('flipped');
          playVoice(3);
        }
      }, 500);

    }
    if (className == 'hiddenCard2') {
      setTimeout(() => {
        const hiddenCard2 = document.querySelector('.' + className);
        if (hiddenCard2) {
          hiddenCard2.classList.add('flipped');
          playVoice(3);
        }
      }, 1000);
    }
    if (className == 'hiddenCard3') {
      setTimeout(() => {
        const hiddenCard3 = document.querySelector('.' + className);
        if (hiddenCard3) {
          hiddenCard3.classList.add('flipped');
          playVoice(3);
        }
      }, 1500);
    }
    if (className == 'hiddenCard4') {
      setTimeout(() => {
        const hiddenCard4 = document.querySelector('.' + className);
        if (hiddenCard4) {
          hiddenCard4.classList.add('flipped');
          playVoice(3);
        }
      }, 500);
    }
    if (className == 'hiddenCard5') {
      setTimeout(() => {
        const hiddenCard5 = document.querySelector('.' + className);
        if (hiddenCard5) {
          hiddenCard5.classList.add('flipped');
          playVoice(3);
        }
      }, 500);
    }
  };

  const addMoreChips = () => {
    setIsOnOpenBuyInPanel(false);
    getReBuyInChips();
  };
  const getReBuyInChips = () => {
    const data = {
      playerId: playerData?.playerId,
      roomId: tableId,
      latitude: 0,
      longitude: 0,
      productName: 'BytePoker',
    };
    socketIo.emit('GetPlayerReBuyInChips', data, (res) => {
      if (res.result) {
        setMinBuyIn(res.result.minBuyIn);
        if (res.result.maxBuyIn > 0) {
          setMaxBuyIn(res.result.maxBuyIn);
        } else {
          setMaxBuyIn(playerData?.chips);
        }
        setStart(res.result.minBuyIn);
        setIsRoomModalOpen(true);
        setTableData(res.result);
        setBuyIn(true);
      }
      if (!res?.result && res.message != '') {
        toast.error(t(res.message), {
          position: 'top-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  };

  const isWinningCard = (card) => {
    if (bestCard.length > 0) {
      if (bestCard.includes(card)) {
        return 'win';
      } else {
        return 'lose';
      }
    } else {
      return '';
    }
  };


  const refreshData = () => { };

  const closeMyProfile = () => {
    setIsSettingOpen(false);
  };

  const closeSetting = () => {
    const data = {
      id: tableId,
      playerId: playerData?.playerId,
      productName: 'BytePoker',
    };
    socket.emit('GetLobbySetting', data, (res) => {
      if (res.status == 'success') {
        setVoice(
          res.result.sound == 'true' || res.result.sound == true ? true : false
        );
      }
    });
  };

  const postflopPotRateAction = (rate) => {
    const { potSizeIfWeCall = 0, lastBetAmount = 0, curYourChipsInTurn = 0, minRaise = 0, maxRaise = 0, raise, bet } = gamePlayData?.buttonAction ?? {};
    if (!potSizeIfWeCall || !minRaise || !maxRaise) {
      return;
    }
    let curRaiseAmount = potSizeIfWeCall * rate + lastBetAmount - curYourChipsInTurn;
    if (curRaiseAmount < maxRaise) {
      curRaiseAmount = Math.floor(curRaiseAmount);
    }
    if (curRaiseAmount < minRaise - curYourChipsInTurn || curRaiseAmount > maxRaise) {
      return;
    }
    raise && PlayerAction(3, true, curRaiseAmount);
    bet && PlayerAction(3, false, curRaiseAmount);
  }

  const canDoPostflopPotRateAction = (rate) => {
    const { potSizeIfWeCall = 0, lastBetAmount = 0, curYourChipsInTurn = 0, minRaise = 0, maxRaise = 0 } = gamePlayData?.buttonAction ?? {};
    if (!potSizeIfWeCall || !minRaise || !maxRaise) {
      return false;
    }
    const curRaiseAmount = potSizeIfWeCall * rate + lastBetAmount - curYourChipsInTurn;
    if (curRaiseAmount < minRaise - curYourChipsInTurn || curRaiseAmount > maxRaise) {
      return false;
    }
    return true;
  }

  const preflopPotRateAction = (rate) => {
    const { curYourChipsInTurn = 0, lastBetAmount = 0, minRaise = 0, maxRaise = 0, raise, bet } = gamePlayData?.buttonAction ?? {};
    if (!minRaise || !maxRaise || !lastBetAmount) {
      return;
    }
    let curRaiseAmount = lastBetAmount * rate - curYourChipsInTurn;
    if (curRaiseAmount < maxRaise) {
      curRaiseAmount = Math.floor(curRaiseAmount);
    }
    if (curRaiseAmount < minRaise - curYourChipsInTurn || curRaiseAmount > maxRaise) {
      return;
    }
    raise && PlayerAction(3, true, curRaiseAmount);
    bet && PlayerAction(3, false, curRaiseAmount);
  }

  const canDoPreflopPotRateAction = (rate) => {
    const { curYourChipsInTurn = 0, lastBetAmount = 0, minRaise = 0, maxRaise = 0 } = gamePlayData?.buttonAction ?? {};
    if (!minRaise || !maxRaise || !lastBetAmount) {
      return false;
    }
    const curRaiseAmount = lastBetAmount * rate - curYourChipsInTurn;
    if (curRaiseAmount < minRaise - curYourChipsInTurn || curRaiseAmount > maxRaise) {
      return false;
    }
    return true;
  }

  const isPlayerTurn =
    gameStart &&
    gamePlayData.roomId === tableId &&
    gamePlayData.playerId == playerData?.playerId;
  const isAway = !!playerInfo.find((player) => {
    if (
      player.id === playerData.playerId &&
      (player.idealPlayer === true ||
        (idealPlayer && idealPlayer.playerId === playerData.playerId))
    ) {
      return true;
    }
    return false;
  });
  const isFolded = !!playerInfo.find((player) => {
    if (player.id === playerData.playerId && (player.status === 'Fold' || player.folded)) {
      return true;
    }
    return false;
  });

  const isWaitForBigBlind = playerInfo.find((player) => {
    const {
      waitForBigBlindCheckbox = false,
      waitForBigBlindCheckboxValue = false,
    } = player.waitForBigBlindData;
    if (player.id === playerData?.playerId && waitForBigBlindCheckbox && waitForBigBlindCheckboxValue) {
      return true;
    }
    return false;
  })

  const sendChatMessage = (message) => {
    if (!message || !socketIo || !playerData || !tableId) {
      return;
    }
    socketIo.emit('Chat', {
      roomId: tableId,
      playerId: playerData.playerId,
      message,
      expiredAt: new Date().getTime() + 3000,
    });
  };

  return (
    <FullScreen handle={handle}>
      <div className="game-screen">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1080 1920"
          className="fixed-screen"
        >
          <image
            x="327"
            y="74"
            width="409"
            height="730"
            xlinkHref={clownImg}
          />
          <image
            x="-24"
            y="383"
            width="1129"
            height="1079"
            xlinkHref={tableBg}
          />
          <CompGameIconButton
            x={945}
            y={65}
            icon={IconNext}
            onClick={() => setShowLeaveMenu(true)}
          />
          {/* <CompGameCalendarButton
            x={924}
            y={166}
            label={playerData?.hour_balance}
          /> */}

          <CompGameIconButton
            x={49}
            y={65}
            onClick={() => {
              setThreeDotShow(!isThreeDotShow);
            }}
          >
            {!isThreeDotShow && (
              <g>
                <circle cx="43" cy="20" r="6" fill="#070D2D"></circle>
                <circle cx="43" cy="42" r="6" fill="#070D2D"></circle>
                <circle cx="43" cy="64" r="6" fill="#070D2D"></circle>
              </g>
            )}
            {isThreeDotShow && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="127"
                height="127"
                fill="none"
                viewBox="0 0 127 127"
                x={-21}
                y={-21}
              >
                <g>
                  <circle cx="63.5" cy="63.5" r="42.5" fill="#070F2E"></circle>
                  <circle
                    cx="63.5"
                    cy="63.5"
                    r="42"
                    stroke="url(#paint0_linear_0_1)"
                  ></circle>
                </g>
                <circle
                  cx="85"
                  cy="63"
                  r="6"
                  fill="#D1E03D"
                  transform="rotate(90 85 63)"
                ></circle>
                <circle
                  cx="63"
                  cy="63"
                  r="6"
                  fill="#D1E03D"
                  transform="rotate(90 63 63)"
                ></circle>
                <circle
                  cx="41"
                  cy="63"
                  r="6"
                  fill="#D1E03D"
                  transform="rotate(90 41 63)"
                ></circle>
                <defs>
                  <linearGradient
                    id="paint0_linear_0_1"
                    x1="21"
                    x2="106"
                    y1="63.947"
                    y2="63.947"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#DD8DAF"></stop>
                    <stop offset="0.505" stopColor="#4E16F9"></stop>
                    <stop offset="1" stopColor="#06BCFF"></stop>
                  </linearGradient>
                </defs>
              </svg>
            )}
          </CompGameIconButton>

          {isThreeDotShow && (
            <>
              {/* Add chips */}
              {
                tournamentType !== 'sng' && <CompGameIconButton
                  x={149}
                  y={65}
                  disabled={tournamentType === 'sng'}
                  onClick={() => {
                    if (tournamentType !== 'sng') {
                      addMoreChips();
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="85"
                    height="85"
                    fill="none"
                    viewBox="0 0 85 85"
                  >
                    <path
                      fill="url(#pattern0_227_367)"
                      d="M15 14H70V71H15z"
                    ></path>
                    <mask
                      id="path-3-outside-1_227_367"
                      width="18"
                      height="17"
                      x="51"
                      y="54"
                      fill="#000"
                      maskUnits="userSpaceOnUse"
                    >
                      <path fill="#fff" d="M51 54H69V71H51z"></path>
                      <path d="M58.05 69.43V55.57h3.87v13.86h-3.87zm-5.19-5.1v-3.66h14.28v3.66H52.86z"></path>
                    </mask>
                    <path
                      fill="#090825"
                      d="M58.05 69.43V55.57h3.87v13.86h-3.87zm-5.19-5.1v-3.66h14.28v3.66H52.86z"
                    ></path>
                    <path
                      fill="#86AACB"
                      d="M58.05 69.43h-1a1 1 0 001 1v-1zm0-13.86v-1a1 1 0 00-1 1h1zm3.87 0h1a1 1 0 00-1-1v1zm0 13.86v1a1 1 0 001-1h-1zm-9.06-5.1h-1a1 1 0 001 1v-1zm0-3.66v-1a1 1 0 00-1 1h1zm14.28 0h1a1 1 0 00-1-1v1zm0 3.66v1a1 1 0 001-1h-1zm-8.09 5.1V55.57h-2v13.86h2zm-1-12.86h3.87v-2h-3.87v2zm2.87-1v13.86h2V55.57h-2zm1 12.86h-3.87v2h3.87v-2zm-8.06-4.1v-3.66h-2v3.66h2zm-1-2.66h14.28v-2H52.86v2zm13.28-1v3.66h2v-3.66h-2zm1 2.66H52.86v2h14.28v-2z"
                      mask="url(#path-3-outside-1_227_367)"
                    ></path>
                    <defs>
                      <pattern
                        id="pattern0_227_367"
                        width="1"
                        height="1"
                        patternContentUnits="objectBoundingBox"
                      >
                        <use
                          transform="matrix(.0062 0 0 .00599 -.006 0)"
                          xlinkHref="#image0_227_367"
                        ></use>
                      </pattern>
                      <image
                        id="image0_227_367"
                        width="163"
                        height="167"
                        xlinkHref={chipBW}
                      ></image>
                    </defs>
                  </svg>
                </CompGameIconButton>
              }

              {/* Settings */}
              <CompGameIconButton
                x={tournamentType !== 'sng' ? 249 : 149}
                y={65}
                onClick={() => {
                  setIsOpenMenu(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="85"
                  height="85"
                  fill="none"
                  viewBox="0 0 85 85"
                >
                  <path
                    fill="#090825"
                    stroke="#090825"
                    strokeLinecap="round"
                    strokeWidth="4"
                    d="M43 47.16a6.16 6.16 0 100-12.32 6.16 6.16 0 000 12.32z"
                  ></path>
                  <path
                    stroke="#090825"
                    strokeLinecap="round"
                    strokeWidth="4"
                    d="M38.6 19l-1.76 5.28-5.28 3.52-7.04-1.76L21 33.08l5.28 5.28v5.28L21 48.92l3.52 7.04 7.04-1.76 5.28 3.52L38.6 63h8.8l1.76-5.28 5.28-3.52 7.04 1.76L65 48.92l-5.28-5.28v-5.28L65 33.08l-3.52-7.04-7.04 1.76-5.28-3.52L47.4 19h-8.8z"
                  ></path>
                </svg>
              </CompGameIconButton>
            </>
          )}

          {/* <CompGameIconButton x={49} y={165}>
            <path
              fill="#070D2D"
              d="M42.5 72c-3.82 0-7.397-.679-10.733-2.036-3.335-1.357-6.238-3.19-8.708-5.5a26.122 26.122 0 01-5.882-8.143C15.726 53.202 15 49.857 15 46.286h6.111c0 5.571 2.075 10.297 6.226 14.178 4.15 3.881 9.205 5.822 15.163 5.822s11.013-1.94 15.163-5.822c4.15-3.88 6.226-8.607 6.226-14.178 0-5.572-2.075-10.298-6.226-14.179-4.15-3.88-9.205-5.821-15.163-5.821h-.458l4.736 4.428-4.278 4.143L30.278 23.43 42.5 12l4.278 4.143-4.736 4.428h.458c3.82 0 7.397.679 10.733 2.036 3.335 1.357 6.238 3.19 8.708 5.5a26.124 26.124 0 015.882 8.143C69.274 39.369 70 42.714 70 46.286c0 3.571-.726 6.916-2.177 10.035a26.122 26.122 0 01-5.882 8.143c-2.47 2.31-5.373 4.143-8.708 5.5C49.897 71.321 46.319 72 42.5 72z"
            ></path>
          </CompGameIconButton> */}

          {/* <CompGameIconButton x={49} y={265} icon={IconShop} /> */}

          {/* <CompGameIconButton x={49} y={1438}>
            <path
              fill="#070D2D"
              d="M61.629 37.58c1.244 0 2.264.988 2.363 2.246l.008.2v19.932c0 4.338-3.328 7.874-7.492 8.036L56.21 68H29.79c-4.202 0-7.627-3.435-7.784-7.734L22 59.958V40.027c0-1.352 1.061-2.448 2.371-2.448 1.244 0 2.264.99 2.363 2.247l.008.2v19.932c0 1.665 1.253 3.029 2.84 3.14l.208.007h26.42c1.613 0 2.934-1.294 3.041-2.932l.007-.215V40.027c0-1.352 1.062-2.448 2.371-2.448zM42.321 18.022l.202-.02.236-.003.21.018.24.045.094.026c.265.075.52.2.75.372l.162.133.126.12 10.538 10.88a2.504 2.504 0 010 3.461c-.871.9-2.252.953-3.184.16l-.169-.16-6.494-6.704v26.963c0 1.284-.958 2.337-2.176 2.44l-.195.008c-1.244 0-2.264-.99-2.363-2.247l-.008-.2V26.355l-6.487 6.7c-.872.9-2.253.952-3.184.158l-.17-.159a2.507 2.507 0 01-.153-3.287l.154-.174 10.503-10.845a2.43 2.43 0 01.242-.227l.154-.113c.05-.037.104-.07.16-.102l.025-.013c.24-.136.505-.23.787-.271z"
            ></path>
          </CompGameIconButton> */}
          <CompGameIconButton
            x={49}
            y={1538}
            // y={1638}
            icon={IconQuestion}
            onClick={() => setOpenCardInfo(true)}
          />
          <CompGameIconButton
            x={49}
            y={1638}
            icon={IconMessage}
            onClick={() => {
              setIsShowChatbox(true);
            }}
          />

          {
            isWaitForBigBlind && <CompSvgText x={0} width={1080} y={1540} textAlign='center' height={48} fontSize={32} >
              {`Wait for big blind`}
            </CompSvgText>
          }

          {
            (parentRoomId && playerInfo.length === 1) && <CompSvgText x={0} width={1080} y={1540} textAlign='center' height={48} fontSize={32} >
              {`All tables are full now, please wait...`}
            </CompSvgText>
          }

          {isAway ? (
            <CompGameButton
              x={375}
              y={1751}
              label={"I'm Back"}
              onClick={() => sendPlayerOnline()}
            />
          ) : (
            !isFolded &&
            isPanelOpen &&
            gamePlayData.playerId == playerData.playerId &&
            gamePlayData.buttonAction && (
              <>
                {
                  gamePlayData.buttonAction.helloRoundName !== "Preflop" && (<>
                    <CompGamePotButton disabled={!canDoPostflopPotRateAction(0.33)} x={575} y={1610} label={'33%'} onClick={() => postflopPotRateAction(0.33)} />
                    <CompGamePotButton disabled={!canDoPostflopPotRateAction(0.5)} x={685} y={1610} label={'50%'} onClick={() => postflopPotRateAction(0.5)} />
                    <CompGamePotButton disabled={!canDoPostflopPotRateAction(0.75)} x={795} y={1610} label={'75%'} onClick={() => postflopPotRateAction(0.75)} />
                    <CompGamePotButton disabled={!canDoPostflopPotRateAction(1.0)} x={905} y={1610} label={'100%'} onClick={() => postflopPotRateAction(1)} />
                  </>)
                }

                {
                  gamePlayData.buttonAction.helloRoundName === "Preflop" && (<>
                    <CompGamePotButton disabled={!canDoPreflopPotRateAction(2)} x={575} y={1610} label={'2x'} onClick={() => preflopPotRateAction(2)} />
                    <CompGamePotButton disabled={!canDoPreflopPotRateAction(3)} x={685} y={1610} label={'3x'} onClick={() => preflopPotRateAction(3)} />
                    <CompGamePotButton disabled={!canDoPreflopPotRateAction(4)} x={795} y={1610} label={'4x'} onClick={() => preflopPotRateAction(4)} />
                    <CompGamePotButton disabled={!canDoPreflopPotRateAction(5)} x={905} y={1610} label={'5x'} onClick={() => preflopPotRateAction(5)} />
                  </>)
                }

                <CompGameButton
                  disabled={!isPlayerTurn}
                  x={25}
                  y={1751}
                  label={'Fold'}
                  onClick={() => PlayerAction(6, false, 0)}
                />
                {gamePlayData.buttonAction.check && (
                  <CompGameButton
                    disabled={!isPlayerTurn}
                    x={375}
                    y={1751}
                    label={'Check'}
                    onClick={() => PlayerAction(2, false, 0)}
                  />
                )}
                {gamePlayData.buttonAction.call && (
                  <CompGameButton
                    disabled={!isPlayerTurn}
                    x={375}
                    y={1751}
                    label={`Call ${~~gamePlayData.buttonAction.callAmount}`}
                    onClick={() => PlayerAction(4, false, 2)}
                  />
                )}
                {gamePlayData.buttonAction.raise && (
                  <CompGameButton
                    disabled={!isPlayerTurn}
                    x={721}
                    y={1751}
                    label={'Raise'}
                    onClick={() => {
                      setRaiseAmount(0);
                      setIsRaiseAmount(true);
                    }}
                  />
                )}
                {gamePlayData.buttonAction.bet && (
                  <CompGameButton
                    disabled={!isPlayerTurn}
                    x={721}
                    y={1751}
                    label={'Bet'}
                    onClick={() => {
                      setRaiseAmount(0);
                      setIsRaiseAmount(true);
                    }}
                  />
                )}
                {gamePlayData.buttonAction.allIn && (
                  <CompGameButton
                    disabled={!isPlayerTurn}
                    x={721}
                    y={1751}
                    label={`All In ${~~gamePlayData.buttonAction.allInAmount}`}
                    onClick={() =>
                      PlayerAction(
                        8,
                        true,
                        gamePlayData.buttonAction.allInAmount
                      )
                    }
                  />
                )}
              </>
            )
          )}
        </svg>

        <ComppViewport width={1080} height={1920}>
          <div className="fixed-screen poker-root">
            {isSettingOpen && (
              <TableSetting
                refreshData={refreshData}
                closeMyProfile={closeMyProfile}
                closeSetting={closeSetting}
              ></TableSetting>
            )}

            <div
              className={`game-page poker-alpha mobile portrait`}
            >
              <div
                className="game-container"
                style={mobileView}
              >
                <div className="game-container-elements">
                  {playerGiftData && playerGiftData.receriverSeatIndex ? (
                    <div className="text-center">
                      <div className="gifting-msg">
                        <h4>
                          <img
                            src={
                              BackendURL +
                              'gameGift/' +
                              playerGiftData.gift.image
                            }
                          ></img>{' '}
                          {playerGiftData.senderPlayerName} sent{' '}
                          {playerGiftData.gift.name} to{' '}
                          {playerGiftData.receiverPlayerName}.
                        </h4>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div
                    className={'poker-game game-container'}
                  >
                    <div
                      className={'r-scene-game-wrapper panel seats-count-8 theme-default background-5 felt-1 card-back-default cs-normal'}
                    >
                      <div className="p-scene-game-table">
                        <div className="p-scene-game-table-cell">
                          <div className="p-game-area" style={tableImage}>
                            <div className="background-table">

                              <div
                                className="background-table-logo"
                                style={{
                                  backgroundImage: `url('${tableLogo}')`,
                                }}
                              ></div>

                              <div className="table-name">
                                {roomData.roomName}
                                {' - '}
                                <span style={{ textTransform: 'capitalize' }}>
                                  {roomData.tableType}
                                  {' ,'}
                                </span>
                                <span className="color-2">
                                  {'Stakes : '}
                                  {smallBlindChips}
                                  {'/'}
                                  {bigBlindChips}
                                </span>
                              </div>
                              <div className="bank-container">
                                <div className="bank-container-content">
                                  Pot:{' '}
                                  {gameStart == true ? ~~tablePot : '0'}
                                </div>
                              </div>
                            </div>
                            <div>
                              {!emptyTable ? (
                                <div>
                                  <div className="r-table-cards max-cards-5">
                                    <div className="card-holder">
                                      {hiddenCards.length > 0 ? (
                                        <div
                                          className={`r-card line-0 card-0  hiddenCard slide-in hiddenCard1`}
                                          onLoad={() =>
                                            hidddenCardClassName('hiddenCard1')
                                          }
                                          style={{
                                            left: '0%',
                                            opacity: 1,
                                            transform:
                                              'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                                            zIindex: 'auto',
                                          }}
                                        >
                                          <div
                                            className={`flip-card-inner ${isWinningCard(hiddenCards[0])}`}
                                          >
                                            <div className="flip-card-front">
                                              <img
                                                alt="poker"
                                                src={cardBack}
                                              ></img>
                                            </div>
                                            <div
                                              className="flip-card-back"
                                              onLoad={() => cardFlipice(0)}
                                            >
                                              <img
                                                alt="poker"
                                                src={cardFronts[hiddenCards[0]]}
                                              ></img>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                      {hiddenCards.length > 1 ? (
                                        <div
                                          className={`r-card line-1 card-1  hiddenCard slide-in hiddenCard2`}
                                          onLoad={() =>
                                            hidddenCardClassName('hiddenCard2')
                                          }
                                          style={{
                                            left: '20%',
                                            opacity: 1,
                                            transform:
                                              'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                                            zIindex: 'auto',
                                          }}
                                        >
                                          <div
                                            className={`flip-card-inner ${isWinningCard(hiddenCards[1])}`}
                                          >
                                            <div className="flip-card-front">
                                              <img
                                                alt="poker"
                                                src={cardBack}
                                              ></img>
                                            </div>
                                            <div
                                              className="flip-card-back"
                                              onLoad={() => cardFlipice(1)}
                                            >
                                              <img
                                                alt="poker"
                                                src={cardFronts[hiddenCards[1]]}
                                              ></img>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                      {hiddenCards.length > 2 ? (
                                        <div
                                          className={`r-card line-2 card-2  hiddenCard slide-in hiddenCard3`}
                                          onLoad={() =>
                                            hidddenCardClassName('hiddenCard3')
                                          }
                                          style={{
                                            left: '40%',
                                            opacity: 1,
                                            transform:
                                              'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                                            zIindex: 'auto',
                                          }}
                                        >
                                          <div
                                            className={`flip-card-inner ${isWinningCard(hiddenCards[2])}`}
                                          >
                                            <div className="flip-card-front">
                                              <img
                                                alt="poker"
                                                src={cardBack}
                                              ></img>
                                            </div>
                                            <div
                                              className="flip-card-back"
                                              onLoad={() => cardFlipice(2)}
                                            >
                                              <img
                                                alt="poker"
                                                src={cardFronts[hiddenCards[2]]}
                                              ></img>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}

                                      {hiddenCards.length > 3 ? (
                                        <div
                                          className={`r-card line-3 card-3  hiddenCard slide-in hiddenCard4`}
                                          onLoad={() =>
                                            hidddenCardClassName('hiddenCard4')
                                          }
                                          style={{
                                            left: '60%',
                                            opacity: 1,
                                            transform:
                                              'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                                            zIindex: 'auto',
                                          }}
                                        >
                                          <div
                                            className={`flip-card-inner ${isWinningCard(hiddenCards[3])}`}
                                          >
                                            <div className="flip-card-front">
                                              <img
                                                alt="poker"
                                                src={cardBack}
                                              ></img>
                                            </div>
                                            <div
                                              className="flip-card-back"
                                              onLoad={() => cardFlipice(3)}
                                            >
                                              <img
                                                alt="poker"
                                                src={cardFronts[hiddenCards[3]]}
                                              ></img>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                      {hiddenCards.length > 4 ? (
                                        <div
                                          className={`r-card line-4 card-4  hiddenCard slide-in hiddenCard5`}
                                          onLoad={() =>
                                            hidddenCardClassName('hiddenCard5')
                                          }
                                          style={{
                                            left: '80%',
                                            opacity: 1,
                                            transform:
                                              'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                                            zIindex: 'auto',
                                          }}
                                        >
                                          <div
                                            className={`flip-card-inner ${isWinningCard(hiddenCards[4])}`}
                                          >
                                            <div className="flip-card-front">
                                              <img
                                                alt="poker"
                                                src={cardBack}
                                              ></img>
                                            </div>
                                            <div
                                              className="flip-card-back"
                                              onLoad={() => cardFlipice(4)}
                                            >
                                              <img
                                                alt="poker"
                                                src={cardFronts[hiddenCards[4]]}
                                              ></img>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  <div className="r-dealer-layer">
                                    {dealerSeatIndex !== -1 && <div>
                                      <div
                                        className={`dealer-target seat-template-${getFillCss(dealerSeatIndex)}`}
                                      >
                                        <div
                                          className="image dealer-button"
                                          style={{
                                            backgroundImage: `url(${dealerIcon})`,
                                            zIndex: 'auto',
                                          }}
                                        ></div>
                                      </div>
                                    </div>}
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}

                              <div className="r-table-chips-layer with-pot-component">
                                {playerSidePot > 0 ? (
                                  <div className="ReactPokerPotsContainer ReactPokerPotsContainer_v_default ReactPokerPotsContainer_c_dark ReactPokerPotsContainer_horizontal pots">
                                    <div className="ReactPokerPotsContainer__content">
                                      <div className="ReactPokerPotsContainer__chips">
                                        <div className="r-chip direction-right chip-columns ReactPokerPotsContainer__chip">
                                          <div className="text">
                                            <span className="value">
                                              {~~playerSidePot}
                                            </span>
                                          </div>
                                          <div className="r-chip-stack">
                                            <div className="columns">
                                              <div className="column col0">
                                                <div className="chip u-5">
                                                  <div
                                                    className="chip-image"
                                                    style={{
                                                      backgroundImage: `url(${chipsPrimary})`,
                                                    }}
                                                  ></div>
                                                </div>
                                                {playerSidePot > 1 ? (
                                                  <div className="chip u-5">
                                                    <div
                                                      className="chip-image"
                                                      style={{
                                                        backgroundImage: `url(${chipsPrimary})`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                                {playerSidePot > 2 ? (
                                                  <div>
                                                    <div className="chip u-5">
                                                      <div
                                                        className="chip-image"
                                                        style={{
                                                          backgroundImage: `url(${chipsPrimary})`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                    <div className="chip u-5">
                                                      <div
                                                        className="chip-image"
                                                        style={{
                                                          backgroundImage: `url(${chipsPrimary})`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ''
                                                )}
                                              </div>
                                              {playerSidePot > 4 ? (
                                                <div className="column col0">
                                                  <div className="chip u-5">
                                                    <div
                                                      className="chip-image"
                                                      style={{
                                                        backgroundImage: `url(${chipsSecondary})`,
                                                      }}
                                                    ></div>
                                                  </div>
                                                  {playerSidePot > 5 ? (
                                                    <div className="chip u-5">
                                                      <div
                                                        className="chip-image"
                                                        style={{
                                                          backgroundImage: `url(${chipsSecondary})`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {playerSidePot > 6 ? (
                                                    <div>
                                                      <div className="chip u-5">
                                                        <div
                                                          className="chip-image"
                                                          style={{
                                                            backgroundImage: `url(${chipsSecondary})`,
                                                          }}
                                                        ></div>
                                                      </div>
                                                      <div className="chip u-5">
                                                        <div
                                                          className="chip-image"
                                                          style={{
                                                            backgroundImage: `url(${chipsSecondary})`,
                                                          }}
                                                        ></div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {!emptyTable && (
                                  <div>
                                    {tableSeats?.map((seat, key) => {
                                      const player = playerInfo?.find(p => checkPlayerSeat(p, seat));
                                      let betAmount = player?.BetAmount ?? 0;
                                      if (!player || !gameStart || !betAmount || !gamePlayData?.buttonAction) {
                                        return null;
                                      }
                                      if (gamePlayData?.buttonAction?.helloBets && !gamePlayData?.buttonAction?.allIn) {
                                        betAmount = gamePlayData?.buttonAction?.helloBets[playerInfo.indexOf(player)];
                                      }
                                      if (!betAmount) {
                                        return null;
                                      }
                                      const multiplier = betAmount / (smallBlindChips ?? 1);
                                      return (
                                        <div
                                          key={key}
                                          className={`r-player-bet s-2 seat-template-${getFillCss(seat)} right-direction`}
                                        >
                                          <div className="r-player-bet-content">
                                            <div
                                              className="r-chip direction-right chip-columns wait-remove"
                                              style={{
                                                zIndex: 'auto',
                                              }}
                                            >
                                              <div className="text">
                                                <span className="value">
                                                  {
                                                    ~~betAmount
                                                  }
                                                </span>
                                              </div>
                                              <div className="r-chip-stack">
                                                <div className="columns">
                                                  <div className="column col0">
                                                    <div className="chip c-1">
                                                      <div
                                                        className="chip-image"
                                                        style={{
                                                          backgroundImage: `url(${chipsPrimary})`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                    {multiplier >
                                                      1 && (
                                                        <div className="chip c-1">
                                                          <div
                                                            className="chip-image"
                                                            style={{
                                                              backgroundImage: `url(${chipsPrimary})`,
                                                            }}
                                                          ></div>
                                                        </div>
                                                      )}
                                                  </div>
                                                  {multiplier >
                                                    2 && (
                                                      <div className="column col">
                                                        <div className="chip c-1">
                                                          <div
                                                            className="chip-image"
                                                            style={{
                                                              backgroundImage: `url(${chipsPrimary})`,
                                                            }}
                                                          ></div>
                                                        </div>
                                                        <div className="chip c-1">
                                                          <div
                                                            className="chip-image"
                                                            style={{
                                                              backgroundImage: `url(${chipsPrimary})`,
                                                            }}
                                                          ></div>
                                                        </div>
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                              <div className="r-seats">
                                {
                                  tableSeats?.map(
                                    (seat, key) => {
                                      const player = playerInfo?.find(p => checkPlayerSeat(p, seat));
                                      if (player) {
                                        let cards = [];
                                        if (player.id === playerData?.playerId) {
                                          cards = playerCards?.cards ?? [];
                                        } else {
                                          cards = gameFinishedPlayersCards?.playersCards?.find(p => p.playerId === player.id)?.cards ?? [];
                                        }
                                        let winner = winners.find(w => w.playerId === player.id);
                                        if (!winner && player.seatIndex === winnerSeatIndex) {
                                          winner = player;
                                          winner.winningAmount = winningAmount;
                                        }
                                        let emoji = '';
                                        const msg = gameChatMessages.find(m => m.playerId === player.id);
                                        if (msg && msg.expiredAt > Date.now()) {
                                          emoji = msg.message;
                                        }
                                        return <CompPlayerSeat
                                          seat={getFillCss(seat)}
                                          cards={cards}
                                          key={key}
                                          player={player}
                                          actionName={player.id === playerActionData?.playerId ? getActionName(playerActionData) : ''}
                                          gameStart={gameStart}
                                          isCurrentPlayer={playerData?.playerId === player.id}
                                          isPlayerTurn={gamePlayData?.playerId === player.id}
                                          isWinning={!!winner}
                                          onAvatarClick={() => showPlayerInfoPopup(player.id)}
                                          winningAmount={winner?.winningAmount ?? 0}
                                          winningType={winner?.winningType ?? ''}
                                          bestCard={winner?.bestCards ?? []}
                                          winnerChips={winner?.chips ?? 0}
                                          seatIndex={key}
                                          gameType={gameType}
                                          timer={!gamePlayData.buttonAction || !isPanelOpen ? 0 : gamePlayData?.timer ?? 0}
                                          maxTimer={gamePlayData?.maxTimer ?? 0}
                                          emoji={emoji}
                                        />
                                      }
                                      return null;
                                    }
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ComppViewport>

        {isShowLeaveMenu && (
          <CompLeaveMenu
            isTournamentTable={isTournamentTable}
            onClose={() => setShowLeaveMenu(false)}
            onLeaveTable={() => leaveGame()}
            onLobby={() => navigate('/lobby')}
            onStandUp={() => {
              standUp();
              setShowLeaveMenu(false);
            }}
            onSitOutNextHand={() => {
              sitOutCheckbox(
                {
                  target: {
                    checked: true,
                  },
                },
                0
              );
              setShowLeaveMenu(false);
            }}
          />
        )}

        <CompBuyInModal
          buyIn={buyIn}
          show={isRoomModalOpen && tableData}
          value={start}
          min={minBuyIn}
          max={maxBuyIn}
          steps={bigBlindChips || 1}
          available={tableData?.playerChips ?? 0}
          onClose={() => {
            setIsRoomModalOpen(false);
            if (!buyIn) {
              leaveGame();
            }
          }}
          onChange={(val) => {
            setStart(val);
          }}
          onSelect={() => {
            sitOnTable(0);
          }}
          onAdd={() => {
            sitOnTable(1);
          }}
        />
      </div>
      {isRaiseAmount && gamePlayData && gamePlayData?.buttonAction && (
        <CompRaiseSlider
          min={gamePlayData?.buttonAction?.minRaise}
          max={
            Math.max(
              gamePlayData?.buttonAction?.minRaise,
              (gamePlayData?.buttonAction?.maxRaise ?? 0) +
              (gamePlayData?.buttonAction?.curYourChipsInTurn ?? 0))
          }
          steps={smallBlindChips || 1}
          onCancel={() => {
            setIsRaiseAmount(false);
            setRaiseAmount(0);
          }}
          onRaise={() => {
            gamePlayData?.buttonAction?.raise &&
              PlayerAction(3, true, raiseAmount);
            gamePlayData?.buttonAction?.bet &&
              PlayerAction(3, false, raiseAmount);
            setIsRaiseAmount(false);
            setRaiseAmount(0);
          }}
          onChange={(value) => {
            raiseAmountChange(
              value - (gamePlayData?.buttonAction?.curYourChipsInTurn ?? 0)
            );
          }}
          isRaise={!!gamePlayData?.buttonAction?.raise}
        />
      )}
      <CompPlayerInfoPopupContainer />
      <CompSettingMenu
        isOpen={isOpenMenu}
        onClose={() => setIsOpenMenu(false)}
      />
      <CompCardInfoModal
        isOpen={isOpenCardInfo}
        onClose={() => setOpenCardInfo(false)}
      />
      <CompTournamentWinnerModal
        isOpen={isTournamentWinnerDisplay}
        data={tournamentWinner}
        onClose={() => {
          setIsTournamentWinnerDisplay(false);
          navigate('/tournament');
        }}
      />
      <CompOpenBuyInModal
        isOpen={isOnOpenBuyInPanel}
        onClose={() => {
          setIsOnOpenBuyInPanel(false);
          leaveGame();
        }}
        onOK={() => {
          addMoreChips();
        }}
      />

      <CompChatbox show={isShowChatbox} onChat={(emoji) => {
        sendChatMessage(emoji);
        setIsShowChatbox(false);
      }} onClose={() => {
        setIsShowChatbox(false);
      }} />

      <CompPlayerEmojiBar emojis={reactEmojis} onChat={(emoji) => {
        sendChatMessage(emoji);
      }} />

      <ToastContainer />
      <CompLoading show={isLoader} />
    </FullScreen>
  );
};

export default GameScreen;
